.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .blog-main-title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: #333;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .blog-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .blog-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover .blog-image {
    transform: scale(1.05);
  }
  
  .blog-content {
    padding: 1.5rem;
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  .blog-excerpt {
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .blog-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.9rem;
    color: #777;
  }
  
  .blog-author, .blog-date, .blog-reading-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .blog-grid {
      grid-template-columns: 1fr;
    }
    
    .blog-container {
      padding: 1rem;
    }
  }
  