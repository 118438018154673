@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
    font-family: 'Inter', sans-serif;
  }
  
  body {
    @apply bg-gradient-to-br from-blue-50 to-indigo-50 min-h-screen;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply tracking-tight font-bold;
  }
  /* Improve article readability */
  .prose {
    @apply max-w-none;
  }
  
  .prose h1 {
    @apply text-3xl font-bold mb-4;
  }
  
  .prose h2 {
    @apply text-2xl font-semibold mb-3;
  }
  
  .prose p {
    @apply text-gray-600 mb-4 leading-relaxed;
  }
  
  .prose ul {
    @apply list-disc list-inside mb-4;
  }
  
  .prose li {
    @apply mb-2;
  }
}

/* Responsive container padding */
@layer components {
  .container-padding {
    @apply px-4 sm:px-6 lg:px-8;
  }

  .input-range {
    @apply w-full h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer;
  }
  
  .input-range::-webkit-slider-thumb {
    @apply appearance-none w-5 h-5 bg-blue-600 rounded-full cursor-pointer hover:bg-blue-700 transition-colors;
    box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.1);
  }
  
  .card {
    @apply bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100;
  }

  .btn-primary {
    @apply bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200 font-medium;
  }

  .input-field {
    @apply w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200;
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
