@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.feature-carousel {
  @apply relative w-full max-w-6xl mx-auto;
}

.feature-carousel-container {
  @apply overflow-hidden;
}

.feature-carousel-slider {
  @apply flex transition-transform duration-1000 ease-in-out;
}

.feature-card {
  @apply w-64 h-64 flex flex-col justify-between m-2 flex-shrink-0;
}

.feature-icon {
  @apply w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4;
}

.carousel-button {
  @apply absolute top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 shadow-md;
}

.carousel-button-left {
  @apply left-0;
}

.carousel-button-right {
  @apply right-0;
}

.page-container {
  @apply min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100;
}

.header {
  @apply bg-white shadow-md;
}

.header-content {
  @apply max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center;
}

.main-content {
  @apply max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8;
}

.welcome-section {
  @apply text-center mb-16;
}

.exams-section {
  @apply bg-white rounded-lg shadow-xl p-8 mb-16;
}

.cta-section {
  @apply text-center;
}