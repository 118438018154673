.homePage {
    min-height: 100vh;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
  }
  
  .header {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .headerContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4a90e2;
  }
  
  .button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color:  #3498db;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #3a7bc8;
  }
  
  .outlineButton {
    background-color: transparent;
    border: 1px solid  #3498db;
    color:  #3498db;
    margin-right: 0.5rem;
  }
  
  .outlineButton:hover {
    background-color:  #3498db;
    color: white;
  }
  
  .main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .heroTitle {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .heroSubtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .featureSection {
    margin-bottom: 3rem;
    overflow: hidden;
  }
  
  .carouselWrapper {
    position: relative;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow: visible;
  }
  
  .featureCard {
    flex: 0 0 25%;
    padding: 1rem;
    text-align: center;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    /* overflow: hidden;  */
  }
  
  .featureCard:hover {
    transform: scale(1.05);
  }

  .iconWrapper {
    background-color: #e9ecef;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
  }
  
  .icon {
    color: #4a90e2;
  }
  
  .featureTitle {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .featureDescription {
    color: #666;
    font-size: 0.9rem;
  }
  
  .carouselButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .prevButton {
    left: 10px;
  }
  
  .nextButton {
    right: 10px;
  }
  
  .examsSection {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 3rem;
  }
  
  .sectionTitle {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .examGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .examCard {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 1.5rem;
  }

  .examCard:hover {
    transform: scale(1.15);
  }

  /* .examCard {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 130px;
    flex: 1;
  }
  
  .examCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  } */
  
  .clickPrompt {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.8rem;
    color: #4a90e2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .examCard:hover .clickPrompt {
    opacity: 1;
  }
  
  .examTitle {
    font-size: 1.2rem;
    color:  #3498db;
    margin-bottom: 0.5rem;
  }
  
  .examDescription {
    color: #666;
    font-size: 1rem; /* Slightly larger font size for better readability */
    line-height: 1.5; /* Improved line spacing */
    margin-bottom: 1.5rem; /* Increased bottom margin for better spacing */
    padding: 0.5rem; /* Added padding for a cleaner look */

  }
  
  
  .cta {
    text-align: center;
  }
  
  .ctaTitle {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .largeButton {
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
  }
  
  @media (max-width: 768px) {
    .featureCard {
      flex: 0 0 50%;
    }
  }
  
  @media (max-width: 480px) {
    .featureCard {
      flex: 0 0 100%;
    }
  }

  .examCardLink {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .additionalContentSection {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 3rem;
  }
  /* .additionalContentSection {
    background-color: #f8f9fa;
    padding: 3rem 0;
    margin-top: 3rem;
  } */
  
  .additionalContentWrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .additionalContent {
    color: #333;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .additionalContent h2 {
    font-size: 1.8rem;
    color: #4a90e2;
    margin-bottom: 1.5rem;
  }
  
  .additionalContent h3 {
    font-size: 1.4rem;
    color: #333;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .additionalContent p {
    margin-bottom: 1rem;
  }
  
  .additionalContent ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .additionalContent li {
    margin-bottom: 0.5rem;
  }